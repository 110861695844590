import React, { Fragment, FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import { convertCompilerOptionsFromJson } from 'typescript';
import { FormContext } from '../../context/FormContext';
import AccountVerification from '../FormItem/AccountVerification';
import Agreement from '../FormItem/Agreement';
import Alert from '../FormItem/Alert';
import DocumentDownload from '../FormItem/DocumentDownload';
import FieldSet from '../FormItem/FieldSet';
import FieldSetExpandableList from '../FormItem/FieldSetExpandableList';
import Footnote from '../FormItem/Footnote';
import RateChart from '../FormItem/RateChart';
import SelectOne from '../FormItem/SelectOne';
import SendSummary from '../FormItem/SendSummary';
import StandardInput from '../FormItem/StandardInput';
import StandardSelect from '../FormItem/StandardSelect';
import Table from '../FormItem/Table';
import VisibilityService from '../../service/VisibilityService';
import StandardCheck from '../FormItem/StandardCheck';

interface IProps {
  formItem:any;
  parentAlias:string;
  count?:number;
  formDirty:boolean;
  validationHandler(alias:string, isValid:boolean):void;
  removeValidationHandler(alias:string):void;
}



const FormItemRenderer:FunctionComponent<IProps> = props => {
    
  const formContext = useContext(FormContext);
  const [isOverriden, setIsOverriden] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const key = props.parentAlias + '.' + props.formItem.alias;


    function getItemRenderer(formItem:any, override:boolean) {
        if (override) {
          return undefined;
        }
        switch(formItem.type) {
            case 'select-one': {
                return <SelectOne validationHandler={props.validationHandler} parentAlias={props.parentAlias} component={formItem} />;
            }
            case 'account-verification': {
              return <AccountVerification validationHandler={props.validationHandler} parentAlias={props.parentAlias} component={formItem} />;
          }
            case 'standard-input': {
                return <StandardInput formDirty={props.formDirty} validationHandler={props.validationHandler} parentAlias={props.parentAlias} component={formItem} />;
            }
            case 'standard-check': {
              return <StandardCheck parentAlias={props.parentAlias} component={formItem} />;
          }
            case 'standard-select': {
              return <StandardSelect isDisabled={isDisabled} formDirty={props.formDirty} validationHandler={props.validationHandler} parentAlias={props.parentAlias} component={formItem} />;
          }
            case 'field-set': {
                 return <FieldSet formDirty={props.formDirty} removeValidationHandler={props.removeValidationHandler} validationHandler={props.validationHandler} count={props.count} parentAlias={props.parentAlias} component={formItem} />;
            }
             case 'field-set-expandable-list': {
               return <FieldSetExpandableList formDirty={props.formDirty} removeValidationHandler={props.removeValidationHandler} validationHandler={props.validationHandler} parentAlias={props.parentAlias} component={formItem} />;
            }
            case 'table': {
              return <Table component={formItem} />;
           }
           case 'rate-chart': {
            return <RateChart  />;
           }
           case 'send-summary': {
            return <SendSummary component={formItem}  />;
           }
           case 'agreement': {
            return <Agreement validationHandler={props.validationHandler} parentAlias={props.parentAlias} component={formItem} />;
           }
           case 'document-download' : {
             return <DocumentDownload component={formItem} />;
           }
           case 'footnote' : {
            return <Footnote component={formItem} />;
          }
          case 'alert' : {
            return <Alert component={formItem} />;
          }
        }
        return <div></div>
    }

    

    

     function showIfWrapper(formItem:any):boolean {
      let show = VisibilityService.showIf(formItem, formContext);
      if(!show && formContext.removeFormData) {
        props.removeValidationHandler(key);
      }
      return show;
     }

     function disableIfWrapper(formItem:any):boolean {
      let show = VisibilityService.disableIf(formItem, formContext);
      if(show && formContext.removeFormData) {
        props.removeValidationHandler(key);
      }
      return show;
     }

     React.useEffect(() => {
      if(props.formItem.hasOwnProperty("showIf")) {
       let visible = showIfWrapper(props.formItem);
       setIsVisible(visible);
      } else {
        setIsVisible(true);
      }
      if(props.formItem.hasOwnProperty("disableIf")) {
        let isDisabled = disableIfWrapper(props.formItem);
        setIsDisabled(isDisabled);
       } else {
        setIsDisabled(false);
       }
    }, [formContext.formRevision])

    React.useEffect(() => {
        if(formContext.updateForm) {
          formContext.updateForm();
        }
    }, [isVisible])

    


  return (
      <Fragment>
    {isVisible && getItemRenderer(props.formItem, isOverriden)}
    </Fragment>
  )
    
  
}

export default FormItemRenderer;